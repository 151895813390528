<template>
  <div>
    <div class="flex justify-between items-center bg-slate-500/25 h-10">
      People
    </div>
    <h1>People</h1>
    <router-link to="/clients/accounts/people/detail" class="hover:underline">
      People Detail
    </router-link>
  </div>
</template>
<script setup lang="ts"></script>
