<template>
  <div class="text-left">
    <div class="flex justify-between items-center bg-slate-500/25 h-10">
      Tasks
    </div>
    <h1 class="m-5 font-semibold text-2xl">Heading</h1>
    <router-link to="/clients/accounts/notes/detail" class="hover:underline">
      Tasks Detail
    </router-link>
  </div>
</template>
<script setup lang="ts"></script>
