/* eslint-disable */
import { defineStore } from "pinia";
import { collection, onSnapshot, 
// doc,
// deleteDoc,
// updateDoc,
// addDoc,
query, orderBy, where, } from "firebase/firestore";
import { db } from "@/js/firebase";
//import { useStoreAuth as storAuth } from '@/stores/storeAuth';
let collectionRef;
let collectionQuery;
export const useNavigationStore = defineStore("storeNavigation", {
    /** state
     * @description state is the current situation and is common accross the applicaiton
     */
    state: () => {
        const aRecords = []; //assigns the type before its added to the return
        return {
            //generic
            storeWorking: true,
            recordLoaded: false,
            //store specific
            requiredTabIndex: 0,
            requiredSection: "",
            currentRecordID: "",
            currentRecord: "",
            allRecords: aRecords, //all records returned when initalised
        };
    },
    /** actions
     * @description functions that are called to perform changes to the store elements.
     */
    actions: {
        /** Initalise */
        init() {
            console.log("storeNavigation being initalised");
            collectionRef = collection(db, "navigation");
            collectionQuery = query(collectionRef, where("current", "==", true), orderBy("order"));
            this.readRecords();
        },
        /** Read Records
         * @description get the record list available to the user dependant on their permissions
         * onSnapshot monitors additions and updates the model
         */
        async readRecords() {
            this.recordLoaded = false;
            onSnapshot(collectionQuery, (querySnapshot) => {
                const aRecords = [];
                querySnapshot.forEach((doc) => {
                    const record = {
                        id: doc.id,
                        section: doc.data().section,
                        rights: doc.data().right,
                        name: doc.data().name,
                        href: doc.data().href,
                        current: doc.data().current,
                        active: doc.data().active,
                    };
                    aRecords.push(record);
                });
                this.allRecords = aRecords; //add result to state
                this.recordLoaded = true;
            }),
                (error) => {
                    console.log("error.message: ", error.message);
                };
        },
    },
    /** getters
     * @description call getters from the vue to extract data from the store - this can be filtered with queries
     */
    getters: {
        getAllRecords: (state) => {
            return {
                foundset: state.allRecords,
            };
        },
        /** Returns the current account for this user
         * @param state
         * @returns
         */
        getCurrentRecord: (state) => {
            return {
                foundset: state.allRecords.filter((record) => record.id === state.currentRecordID)[0],
            };
        },
        getRequiredSectionRecords: (state) => {
            const str = state.requiredSection;
            const newStr = str.replace("/", "");
            console.log("String:", str);
            console.log("NewString", newStr);
            return {
                foundset: state.allRecords.filter((record) => record.section === newStr.split("/")[0]),
            };
        },
        getRequiredSubSectionRecords: (state) => {
            return {
                foundset: state.allRecords.filter((record) => record.section === state.requiredSection),
            };
        },
    },
});
