/* eslint-disable */
import { defineStore } from "pinia";
import { collection, onSnapshot, addDoc, 
//where,
getDocs, query, } from "firebase/firestore";
import { db } from "@/js/firebase";
import { useStoreAuth as storAuth } from '@/stores/storeAuth';
let collectionRef;
let collectionQuery;
let subCollectionRef;
// let subCollectionQuery: any;
export const useAccountStore = defineStore("storeAccount", {
    /** state
     * @description state is the current situation and is common accross the applicaiton
     */
    state: () => {
        const aRecords = []; //assigns the type before its added to the return
        const aNotes = [];
        return {
            //generic
            storeWorking: true,
            recordLoaded: false,
            currentRecordID: "",
            currentRecord: "",
            allRecords: aRecords,
            //specific to Notes
            currentNoteID: "",
            currentNote: "",
            allNotes: aNotes,
            //General
            editStatus: false,
        };
    },
    /** actions
     * @description functions that are called to perform changes to the store elements.
     */
    actions: {
        init() {
            console.log("storeAccount being initalised");
            collectionRef = collection(db, "account");
            collectionQuery = query(collectionRef);
            this.readRecords();
        },
        //** HELPER FUNCTIONS */
        switchEditStatus(status) {
            this.editStatus = status;
            console.log(this.editStatus);
        },
        /** CRUD FUNCTIONS  */
        /** Create Records
         * @description creates the record adding it to the selected collection
         */
        async createRecord(input) {
            const docRef = await addDoc(collectionRef, {
                name: input.name,
            });
            console.log("Document written with ID: ", docRef.id);
            return docRef.id;
        },
        /** Read Records
         * @description get the record list available to the user dependant on their permissions
         * onSnapshot monitors additions and updates the model
         */
        async readRecords() {
            this.recordLoaded = false;
            onSnapshot(collectionQuery, (querySnapshot) => {
                const aRecords = [];
                querySnapshot.forEach((doc) => {
                    const record = {
                        name: doc.data().name,
                    };
                    aRecords.push(record);
                });
                this.allRecords = aRecords; //add result to state
                this.recordLoaded = true;
            }),
                (error) => {
                    console.log("error.message: ", error.message);
                };
        },
        /** Update Record
         * @description Update the contents of the record with the data provided from the form as input parameter
         */
        // async updateRecord(
        //   idToUpdate: string,
        //   input: {
        //   }
        // ) {
        //   await updateDoc(doc(collectionRef, idToUpdate), {
        //     description: input,
        //   });
        // },
        /** Delete Account
         * @description Note this wont delete the acocunt it will archive it and hide it from general use.  People associated with the account will also be blocked from access.
         */
        // async deleteRecord(idToDelete: string) {
        //   await deleteDoc(doc(collectionRef, idToDelete));
        // },
        /** NOTES SUB COLLECTION */
        /** Create note
         */
        async createSubNote(accountID, input) {
            subCollectionRef = collection(db, "accounts", accountID, "notes");
            const docRef = await addDoc(subCollectionRef, {
                summary: input.summary,
                description: input.description,
                createdAt: new Date(),
                createdBy: storAuth().user
            });
            console.log("Document written with ID: ", docRef.id);
            this.readNotes(accountID); //decided against using onSnapshot, pointless use of socket
            return docRef.id;
        },
        /** Read Notes
         * @description get the record list available to the user dependant on their permissions
         * this is not monitored with onSnapshot as there is no likelyhood that a record would be added
         * other than by the current user
         */
        async readNotes(accountID) {
            const aNotes = [];
            subCollectionRef = collection(db, "accounts", accountID, "notes");
            const q = query(subCollectionRef);
            await getDocs(q).then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const record = {
                        uid: doc.id,
                        summary: doc.data().summary,
                        description: doc.data().description,
                        createdAt: doc.data().createdAt,
                        createdBy: doc.data().createdBy,
                    };
                    aNotes.push(record);
                });
            });
            this.allNotes = aNotes;
        },
    },
    /** getters
     * @description call getters from the vue to extract data from the store - this can be filtered with queries
     */
    getters: {
        getAllRecords: (state) => {
            return {
                foundset: state.allRecords,
            };
        },
        getNotes: (state) => {
            return {
                foundset: state.allNotes,
            };
        },
        getNote: (state) => {
            let result = {};
            if (state.currentNoteID) {
                result = state.allNotes.filter((record) => record.uid === state.currentNoteID);
                return {
                    foundset: result
                };
            }
            return {
                foundset: result
            };
        },
        /** Returns the current account for this user
         * @param state
         * @returns
         */
        getCurrentRecord: (state) => {
            let result = {
                id: "",
                Billing: "",
                Branch: "",
                Jobs: "",
                Lead: "",
                Name: "",
                People: "",
                Pets: "",
                Property: "",
                Key: "",
            };
            if (state.currentRecordID) {
                result = state.allRecords.filter((record) => record.id === state.currentRecordID)[0];
                return {
                    foundset: result,
                };
            }
            ;
            return {
                foundset: result,
            };
        },
    },
});
