import { createRouter, createWebHistory } from "vue-router";
import { useStoreAuth as storAuth } from "@/stores/storeAuth";
import IndexView from "@/views/Public/IndexView.vue";
import ForgotView from "@/views/Public/ForgotView.vue";
// Branch components
import baseDashboard from "@/views/Guarded/_Dashboard/baseDashboard.vue";
//Client
import baseClient from "@/views/Guarded/Clients/baseClient.vue";
//Client tabs
import clientDashboard from "@/views/Guarded/Clients/tabDashboard/tabClientDB.vue";
import clientProspects from "@/views/Guarded/Clients/tabProspect/tabClientProspects.vue";
import clientAccounts from "@/views/Guarded/Clients/tabAccount/tabClientAccount.vue";
import clientAccountList from "@/views/Guarded/Clients/tabAccount/AccountList.vue";
//Client Account Sub Tabs
import clientAccountProperties from "@/views/Guarded/Clients/tabAccount/subTabs/subTabProperties.vue";
import clientAccountPropertiesDetail from "@/views/Guarded/Clients/tabAccount/subTabs/tabDetail/subDetailProperties.vue";
import clientAccountPets from "@/views/Guarded/Clients/tabAccount/subTabs/subTabPets.vue";
import clientAccountPetsDetail from "@/views/Guarded/Clients/tabAccount/subTabs/tabDetail/subDetailPets.vue";
import clientAccountPeople from "@/views/Guarded/Clients/tabAccount/subTabs/subTabPeople.vue";
import clientAccountPeopleDetail from "@/views/Guarded/Clients/tabAccount/subTabs/tabDetail/subDetailPeople.vue";
import clientAccountJobs from "@/views/Guarded/Clients/tabAccount/subTabs/subTabJobs.vue";
import clientAccountJobsDetail from "@/views/Guarded/Clients/tabAccount/subTabs/tabDetail/subDetailJobs.vue";
import clientAccountBookings from "@/views/Guarded/Clients/tabAccount/subTabs/subTabBookings.vue";
import clientAccountBookingsDetail from "@/views/Guarded/Clients/tabAccount/subTabs/tabDetail/subDetailBookings.vue";
import clientAccountBilling from "@/views/Guarded/Clients/tabAccount/subTabs/subTabBilling.vue";
import clientAccountBillingDetail from "@/views/Guarded/Clients/tabAccount/subTabs/tabDetail/subDetailBilling.vue";
import clientAccountNotes from "@/views/Guarded/Clients/tabAccount/subTabs/subTabNotes.vue";
import clientAccountNotesDetail from "@/views/Guarded/Clients/tabAccount/subTabs/tabDetail/subDetailNotes.vue";
import clientAccountTasks from "@/views/Guarded/Clients/tabAccount/subTabs/subTabTasks.vue";
import clientAccountTasksDetail from "@/views/Guarded/Clients/tabAccount/subTabs/tabDetail/subDetailTasks.vue";
//Client Prospect Sub Tabs
import clientProspectNotes from "@/views/Guarded/Clients/tabProspect/subTabs/subTabNotes.vue";
import clientProspectNotesDetail from "@/views/Guarded/Clients/tabProspect/subTabs/tabDetail/subDetailNotes.vue";
import clientProspectTasks from "@/views/Guarded/Clients/tabProspect/subTabs/subTabTasks.vue";
import clientProspectTasksDetail from "@/views/Guarded/Clients/tabProspect/subTabs/tabDetail/subDetailTasks.vue";
import clientProspectPeople from "@/views/Guarded/Clients/tabProspect/subTabs/subTabPeople.vue";
import clientProspectPeopleDetail from "@/views/Guarded/Clients/tabProspect/subTabs/tabDetail/subDetailPeople.vue";
//Diary
import baseDiary from "@/views/Guarded/Diary/baseDiary.vue";
import diaryDashboard from "@/views/Guarded/Diary/tabDiaryDashboard.vue";
import diaryCalendar from "@/views/Guarded/Diary/tabDiaryCalendar.vue";
import diaryAvailability from "@/views/Guarded/Diary/tabDiaryAvailability.vue";
import diaryBookings from "@/views/Guarded/Diary/tabDiaryBookings.vue";
//Operations
import baseOperations from "@/views/Guarded/Operations/baseOperations.vue";
import operationsDashboard from "@/views/Guarded/Operations/tabOperationsDashboard.vue";
import operationsDiary from "@/views/Guarded/Operations/tabOperationsCalendar.vue";
import operationsAvailability from "@/views/Guarded/Operations/tabOperationsAvailability.vue";
import operationsBookings from "@/views/Guarded/Operations/tabOperationsBookings.vue";
//Support
import baseSupport from "@/views/Guarded/Support/baseSupport.vue";
import supportDashboard from "@/views/Guarded/Support/tabSupportDashboard.vue";
import supportDesk from "@/views/Guarded/Support/tabSupportHelpdesk.vue";
import supportFAQs from "@/views/Guarded/Support/tabSupportFAQs.vue";
//Marketing
import baseMarketing from "@/views/Guarded/Marketing/baseMarketing.vue";
import marketingDashboard from "@/views/Guarded/Marketing/tabMarketingDashboard.vue";
import marketingDirect from "@/views/Guarded/Marketing/tabMarketingDirect.vue";
import marketingAdvertising from "@/views/Guarded/Marketing/tabMarketingAdvertising.vue";
import marketingReferrals from "@/views/Guarded/Marketing/tabMarketingReferrals.vue";
//Finance
import baseFinance from "@/views/Guarded/Finance/baseFinance.vue";
import financeDashboard from "@/views/Guarded/Finance/tabDashboard/tabFinanceDashboard.vue";
import financeBilling from "@/views/Guarded/Finance/tabBilling/tabFinanceBilling.vue";
import financeExpenses from "@/views/Guarded/Finance/tabExpenses/tabFinanceExpenses.vue";
//Team
import baseTeam from "@/views/Guarded/Team/baseTeam.vue";
import teamDashboard from "@/views/Guarded/Team/tabTeamDashboard.vue";
import teamPeople from "@/views/Guarded/Team/tabTeamPeople.vue";
import teamTraining from "@/views/Guarded/Team/tabTeamTraining.vue";
import teamHolidays from "@/views/Guarded/Team/tabTeamHolidays.vue";
//Safety
import safetyBase from "@/views/Guarded/Safety/baseSafety.vue";
import safetyDashboard from "@/views/Guarded/Safety/tabSafetyDashboard.vue";
import safetyPolicies from "@/views/Guarded/Safety/tabSafetyPolicies.vue";
import safetyActivities from "@/views/Guarded/Safety/tabSafetyActivities.vue";
import safetyDatabank from "@/views/Guarded/Safety/tabSafetyDatabank.vue";
import safetyTraining from "@/views/Guarded/Safety/tabSafetyTraining.vue";
//Messages
import baseMessages from "@/views/Guarded/Messages/baseMessages.vue";
import messagesDashboard from "@/views/Guarded/Messages/tabMessageDashboard.vue";
import messagesActive from "@/views/Guarded/Messages/tabMessageActive.vue";
import messagesArchive from "@/views/Guarded/Messages/tabMessageArchive.vue";
const routes = [
    /** public */
    { path: "/", component: IndexView },
    { path: "/forgot", component: ForgotView },
    /** guarded */
    {
        path: "/dashboard",
        components: { contentPane: baseDashboard },
        meta: { requiredAuth: false },
    },
    //Clients
    {
        path: "/clients",
        name: "clientBase",
        components: { contentPane: baseClient },
        meta: { requiredAuth: false },
        children: [
            //Dashboard
            {
                path: "dashboard",
                components: {
                    contentPane: clientDashboard,
                },
            },
            //Accounts  {Notes, Billing, Bookings, Jobs, People, Pets, Properties}
            {
                path: "accounts",
                components: {
                    contentPane: clientAccounts,
                    sideBar: clientAccountList,
                },
                meta: { requiredAuth: false },
                children: [
                    //Notes
                    {
                        path: "Notes",
                        components: { tabPane: clientAccountNotes },
                    },
                    {
                        path: "Notes/detail",
                        components: { tabPane: clientAccountNotesDetail },
                    },
                    //Tasks
                    {
                        path: "Tasks",
                        components: { tabPane: clientAccountTasks },
                    },
                    {
                        path: "Tasks/detail",
                        components: { tabPane: clientAccountTasksDetail },
                    },
                    //Billing
                    {
                        path: "/clients/accounts/billing",
                        components: { tabPane: clientAccountBilling },
                    },
                    {
                        path: "/clients/accounts/billing/detail",
                        components: { tabPane: clientAccountBillingDetail },
                    },
                    //Bookings
                    {
                        path: "/clients/accounts/bookings",
                        components: { tabPane: clientAccountBookings },
                    },
                    {
                        path: "/clients/accounts/bookings/detail",
                        components: { tabPane: clientAccountBookingsDetail },
                    },
                    //Jobs
                    {
                        path: "/clients/accounts/jobs",
                        components: { tabPane: clientAccountJobs },
                    },
                    {
                        path: "/clients/accounts/jobs/detail",
                        components: { tabPane: clientAccountJobsDetail },
                    },
                    //People
                    {
                        path: "/clients/accounts/people",
                        components: { tabPane: clientAccountPeople },
                    },
                    {
                        path: "/clients/accounts/people/detail",
                        components: { tabPane: clientAccountPeopleDetail },
                    },
                    //Pets
                    {
                        path: "/clients/accounts/pets",
                        components: { tabPane: clientAccountPets },
                    },
                    {
                        path: "/clients/accounts/pets/detail",
                        components: { tabPane: clientAccountPetsDetail },
                    },
                    //Properties
                    {
                        path: "/clients/accounts/properties",
                        components: { tabPane: clientAccountProperties },
                    },
                    {
                        path: "/clients/accounts/properties/detail",
                        components: { tabPane: clientAccountPropertiesDetail },
                    },
                ],
            },
            //Prospects {Notes, People}
            {
                path: "/clients/prospects",
                components: { contentPane: clientProspects },
                meta: { requiredAuth: false },
                children: [
                    //Notes
                    {
                        path: "/clients/prospects/Notes",
                        components: { tabPane: clientProspectNotes },
                    },
                    {
                        path: "/clients/prospects/Notes/detail",
                        components: { tabPane: clientProspectNotesDetail },
                    },
                    //tasks
                    {
                        path: "/clients/prospects/tasks",
                        components: { tabPane: clientProspectTasks },
                    },
                    {
                        path: "/clients/prospects/tasks/detail",
                        components: { tabPane: clientProspectTasksDetail },
                    },
                    //People
                    {
                        path: "/clients/prospects/people",
                        components: { tabPane: clientProspectPeople },
                    },
                    {
                        path: "/clients/prospects/people/detail",
                        components: { tabPane: clientProspectPeopleDetail },
                    },
                ],
            },
        ],
    },
    //Diary
    {
        path: "/diary",
        components: { contentPane: baseDiary },
        meta: { requiredAuth: false, sidePane: true },
        children: [
            {
                path: "/diary/dashboard",
                components: { contentPane: diaryDashboard },
            },
            {
                path: "/diary/calendar",
                components: { contentPane: diaryCalendar },
            },
            {
                path: "/diary/availability",
                components: { contentPane: diaryAvailability },
            },
            {
                path: "/diary/bookings",
                components: { contentPane: diaryBookings },
            },
        ],
    },
    //Operations
    {
        path: "/operations",
        components: { contentPane: baseOperations },
        meta: { requiredAuth: false },
        children: [
            {
                path: "/operations/dashboard",
                components: { contentPane: operationsDashboard },
            },
            {
                path: "/operations/diary",
                components: { contentPane: operationsDiary },
            },
            {
                path: "/operations/availability",
                components: { contentPane: operationsAvailability },
            },
            {
                path: "/operations/bookings",
                components: { contentPane: operationsBookings },
            },
        ],
    },
    //Support
    {
        path: "/support",
        components: { contentPane: baseSupport },
        meta: { requiredAuth: false },
        children: [
            //Dashboard
            {
                path: "/support/dashboard",
                components: { contentPane: supportDashboard },
            },
            //Support Desk {Tickets, Notes, Vouchers}
            {
                path: "/support/supportdesk",
                components: { contentPane: supportDesk },
            },
            {
                path: "/support/faqs",
                components: { contentPane: supportFAQs },
            },
        ],
    },
    //Marketing
    {
        path: "/marketing",
        components: { contentPane: baseMarketing },
        meta: { requiredAuth: false },
        children: [
            //Dashboard
            {
                path: "/sales/dashboard",
                components: { contentPane: marketingDashboard },
            },
            //Direct {PostCode, Campaign, }
            {
                path: "/sales/direct",
                components: { contentPane: marketingDirect },
            },
            //Advertising {Campaign, Key}
            {
                path: "/sales/advertising",
                components: { contentPane: marketingAdvertising },
            },
            //Referrals {Account}
            {
                path: "/sales/referrals",
                components: { contentPane: marketingReferrals },
            },
        ],
    },
    //Finance
    {
        path: "/finance",
        components: { contentPane: baseFinance },
        meta: { requiredAuth: false },
        children: [
            // Dashboard
            {
                path: "/finance/dashboard",
                components: { contentPane: financeDashboard },
            },
            //Billing { Invoices, Credit Notes}
            {
                path: "/finance/billing",
                components: { contentPane: financeBilling },
            },
            //Expenses {Receipts, Payments}
            {
                path: "/finance/expenses",
                components: { contentPane: financeExpenses },
            },
        ],
    },
    //Team
    {
        path: "/team",
        components: { contentPane: baseTeam },
        meta: { requiredAuth: false },
        children: [
            {
                path: "/team/dashboard",
                components: { contentPane: teamDashboard },
            },
            {
                path: "/team/people",
                components: { contentPane: teamPeople },
            },
            {
                path: "/team/training",
                components: { contentPane: teamTraining },
            },
            {
                path: "/team/holidays",
                components: { contentPane: teamHolidays },
            },
        ],
    },
    //Safety
    {
        path: "/safety",
        components: { contentPane: safetyBase },
        meta: { requiredAuth: false },
        children: [
            {
                path: "/safety/dashboard",
                components: { contentPane: safetyDashboard },
            },
            {
                path: "/safety/policies",
                components: { contentPane: safetyPolicies },
            },
            {
                path: "/safety/activities",
                components: { contentPane: safetyActivities },
            },
            {
                path: "/safety/databank",
                components: { contentPane: safetyDatabank },
            },
            {
                path: "/safety/training",
                components: { contentPane: safetyTraining },
            },
        ],
    },
    //Messages
    {
        path: "/messages",
        components: { contentPane: baseMessages },
        meta: { requiredAuth: false },
        children: [
            {
                path: "/messages/dashboard",
                components: { contentPane: messagesDashboard },
            },
            {
                path: "/messages/active",
                components: { contentPane: messagesActive },
            },
            {
                path: "/messages/archive",
                components: { contentPane: messagesArchive },
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
// navigation guards
router.beforeEach(async (to) => {
    //used to set the subnav request
    // storNav().requiredSection = to.path;
    // const isLocalhost =
    //   window.location.hostname === "localhost" ||
    //   window.location.hostname === "127.0.0.1";
    // if (!isLocalhost) {
    //   console.log("Router: You are live");
    //   // bail here to holding page
    // } else {
    //   console.log("Router: you are in localhost");
    // }
    // extract meta from the route and use the beforeEach to check for it
    // if (to.matched.some((record) => record.meta.isManager)) {
    //   console.log("Router: record set to manager ");
    // }
    if (to.matched.some((record) => record.meta.requiredAuth) &&
        !storAuth().user.id) {
        alert("Restricted page please login ....");
        return {
            path: "/",
        };
    }
});
export default router;
