/* eslint-disable */
import { defineStore } from "pinia";
export const useBranchStore = defineStore("storeBranch", {
    state: () => {
        return {
            //generic
            storeWorking: true,
            activeBranch: "KBz4hklREk6ek0OMc1a8",
        };
    },
    actions: {
        /** Initalise */
        init() {
            console.log("storeBranch being initalised");
        },
    },
    getters: {},
});
