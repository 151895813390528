import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
const firebaseConfig = {
    apiKey: "AIzaSyCX25efUaP5XfwENoFrnYsgND9pPn9rfDw",
    authDomain: "clickhelp-68faa.firebaseapp.com",
    projectId: "clickhelp-68faa",
    storageBucket: "clickhelp-68faa.appspot.com",
    messagingSenderId: "850115180199",
    appId: "1:850115180199:web:1a3c8d903fe4a0dac40d82",
    measurementId: "G-BWBW2Y67PM",
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage();
const functions = getFunctions(getApp());
connectFirestoreEmulator(db, "127.0.0.1", 8081);
connectStorageEmulator(storage, "127.0.0.1", 9199);
connectAuthEmulator(auth, "http://127.0.0.1:9099");
connectFunctionsEmulator(functions, "127.0.0.1", 5001);
export { app, db, auth, storage, functions };
