/* eslint-disable */
import { defineStore } from "pinia";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged, updateProfile, } from "firebase/auth";
import { auth } from "@/js/firebase"; //imported from the /js/firebase config not node
import router from "@/router/";
export const useStoreAuth = defineStore("storeAuth", {
    state: () => ({
        testStor: "StorAuth Working",
        mobileMenuOpen: false,
        showMenu: false,
        user: {
            id: "",
            email: "",
        },
    }),
    actions: {
        init() {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    this.user.id = user.uid;
                    this.user.email = user.email;
                    router.replace("/dashboard");
                }
                else {
                    this.user.id = "";
                    this.user.email = "";
                    router.replace("/");
                }
            });
        },
        sendReminder(credentials) {
            console.log(credentials.email);
        },
        registerUser(credentials) {
            createUserWithEmailAndPassword(auth, credentials.email, credentials.password)
                .then((userCredential) => {
                const newCustomer = userCredential.user;
                updateProfile(newCustomer, {
                    displayName: credentials.email,
                }).then(() => {
                    console.log("DISPLAY NAME", newCustomer.email);
                    console.log(auth.currentUser);
                });
            })
                .catch((error) => {
                console.log("error.message:", error.message);
            });
        },
        loginUser(credentials) {
            signInWithEmailAndPassword(auth, credentials.email, credentials.password)
                .then((userCredential) => {
                const user = userCredential.user;
                console.log("USER at login script:", user);
                router.replace("/dashboard");
            })
                .catch((error) => {
                console.log("Error Code: ", error.code);
                console.log("Error Message: ", error.message);
            });
        },
        logoutUser() {
            signOut(auth)
                .then(() => {
                this.user.id = "";
                this.user.email = "";
                router.push("/");
            })
                .catch((error) => {
                console.log("Catch logoutUser", error.message);
            });
        },
    },
    getters: {
        getUserProfile: (state) => {
            return {
                first: state.user.email,
                second: state.user.id,
            };
        },
    },
});
