<template>
  <div>
    <router-link to="/clients/accounts/properties/" class="hover:underline">
      ...Back
    </router-link>
    <div class="flex justify-between items-center bg-slate-500/25 h-10">
      Properties Detail
    </div>
    <h2 class="text-lg font-dm font-semibold">Properties Detail</h2>
  </div>
</template>
<script setup lang="ts"></script>
