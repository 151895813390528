/* eslint-disable */
import { defineStore } from "pinia";
import { collection, onSnapshot, addDoc, query, } from "firebase/firestore";
import { db } from "@/js/firebase";
//import { useStoreAuth as storAuth } from '@/stores/storeAuth';
import { useAccountStore as storAccount } from "@/stores/storeAccount";
let collectionRef;
let collectionQuery;
export const useNotesStore = defineStore("storeNotes", {
    /** state
     * @description state is the current situation and is common accross the applicaiton
     */
    state: () => {
        const aRecords = []; //assigns the type before its added to the return
        return {
            //generic
            storeWorking: "Billing Store Working",
            recordLoaded: false,
            //store specific
            currentRecordID: "",
            currentRecord: "",
            allRecords: aRecords, //all records returned when initalised
        };
    },
    /** actions
     * @description functions that are called to perform changes to the store elements.
     */
    actions: {
        /** Initalise */
        init() {
            console.log("storeNotes being initalised");
            collectionRef = collection(db, "notes");
            collectionQuery = query(collectionRef);
            this.readRecords();
        },
        /** Create Records
         * @description creates the record adding it to the selected collection
         */
        async createRecord(input) {
            const docRef = await addDoc(collectionRef, {
                summary: input.summary,
                description: input.description,
                id_account: storAccount().currentRecordID,
            });
            console.log("Document written with ID: ", docRef.id);
        },
        /** Read Records
         * @description get the record list available to the user dependant on their permissions
         * onSnapshot monitors additions and updates the model
         */
        async readRecords() {
            this.recordLoaded = false;
            onSnapshot(collectionQuery, (querySnapshot) => {
                const aRecords = [];
                querySnapshot.forEach((doc) => {
                    const record = {
                        id: doc.id,
                        summary: doc.data().summary,
                        description: doc.data().description,
                        id_account: doc.data().id_account, //remove this and replace it with an action
                    };
                    aRecords.push(record);
                });
                this.allRecords = aRecords; //add result to state
                this.recordLoaded = true;
            }),
                (error) => {
                    console.log("error.message: ", error.message);
                };
        },
        // /** Update Record
        //  * @description Update the contents of the record with the data provided from the form as input parameter
        //  */
        // async updateRecord(
        //   idToDelete: string,
        //   input: {
        //     id_account: string;
        //   }
        // ) {
        //   await updateDoc(doc(collectionRef, idToDelete), {
        //     description: input,
        //   });
        // },
        // /** Delete Account
        //  * @description Note this wont delete the acocunt it will archive it and hide it from general use.  People associated with the account will also be blocked from access.
        //  */
        // async deleteRecord(idToDelete: string) {
        //   //TODO add alert challenge when on a desktop (see Ionic for multi button alert)
        //   await deleteDoc(doc(collectionRef, idToDelete));
        // },
    },
    /** getters
     * @description call getters from the vue to extract data from the store - this can be filtered with queries
     */
    getters: {
        getAllRecords: (state) => {
            return state.allRecords || "No Records";
        },
        /** Returns the current account for this user
         * @param state
         * @returns
         */
        getCurrentRecord: (state) => {
            // filter and set the state
            state.currentRecord = state.allRecords.filter((record) => record.id === state.currentRecordID)[0];
            // return the current record and if null a message that can be filtered with a conditional
            return state.currentRecord || "No Records";
        },
        getAccountRecords: (state) => {
            return {
                foundset: state.allRecords.filter((record) => record.id_account === storAccount().currentRecordID),
            };
        },
    },
});
