<template>
  <h1>Safety Dashboard</h1>
  <div
    class="grid grid-cols-1 gap-8 px-4 py-6 lg:py-8 md:grid-cols-2 border-b border-slate-200"
  >
    <div class="bg-slate-200">Data here</div>
    <div class="bg-slate-200">Data here</div>
    <div class="bg-slate-200">Data here</div>
    <div class="bg-slate-200">Data here</div>
  </div>
</template>

<script setup lang="ts"></script>
