/* eslint-disable */
import { defineStore } from "pinia";
import { db } from "@/js/firebase";
import { collection, onSnapshot, doc, deleteDoc, updateDoc, addDoc, query, } from "firebase/firestore";
import { useAccountStore as storAccount } from "@/stores/storeAccount";
let collectionRef;
let collectionQuery;
export const useJobStore = defineStore("storeJobs", {
    /** state
     * @description state is the current situation and is common accross the applicaiton
     */
    state: () => {
        const aRecords = []; //assigns the type before its added to the return
        return {
            //generic
            storeWorking: true,
            recordLoaded: false,
            currentRecordID: "",
            currentRecord: "",
            allRecords: aRecords, //all records returned when initalised
        };
    },
    /** actions
     * @description functions that are called to perform changes to the store elements.
     */
    actions: {
        /** Initalise */
        init() {
            collectionRef = collection(db, "jobs");
            collectionQuery = query(collectionRef);
            this.readRecords();
        },
        /** Create Records
         * @description creates the record adding it to the selected collection
         */
        async createRecord(input) {
            const docRef = await addDoc(collectionRef, {
                id_account: input.id_account,
                active: input.active,
                description: input.description,
                category: input.category,
                charge: input.charge,
                day: input.day,
                allDay: input.allDay,
                startTime: input.startTime,
                PPE: input.PPE,
            });
            console.log("Document written with ID: ", docRef.id);
        },
        /** Read Records
         * @description get the record list available to the user dependant on their permissions
         * onSnapshot monitors additions and updates the model
         */
        async readRecords() {
            this.recordLoaded = false;
            onSnapshot(collectionQuery, (querySnapshot) => {
                const aRecords = [];
                querySnapshot.forEach((doc) => {
                    const record = {
                        id: doc.id,
                        active: doc.data().active,
                        allDay: doc.data().allDay,
                        branch: doc.data().branch,
                        idAccount: doc.data().idAccount,
                    };
                    aRecords.push(record);
                });
                this.allRecords = aRecords; //add result to state
                this.recordLoaded = true;
            }),
                (error) => {
                    console.log("error.message: ", error.message);
                };
        },
        /** Update Record
         * @description Update the contents of the record with the data provided from the form as input parameter
         * @type {firstname:string, lastname:string} input - this is a test
         */
        async updateRecord(idToDelete, input) {
            await updateDoc(doc(collectionRef, idToDelete), {
                description: input,
            });
        },
        /** Delete Account
         * @description Note this wont delete the acocunt it will archive it and hide it from general use.  People associated with the account will also be blocked from access.
         */
        async deleteRecord(idToDelete) {
            //TODO add alert challenge when on a desktop (see Ionic for multi button alert)
            await deleteDoc(doc(collectionRef, idToDelete));
        },
    },
    //getters
    getters: {
        /** Returns all records
         * @description call getters from the vue to extract data from the store - this can be filtered with queries
         * @param {object} state
         * @returns
         */
        getAllRecords: (state) => {
            return {
                foundset: state.allRecords, //state.accounts.filter(account => account === state.accounts)[0]
            };
        },
        /** Returns the current record selected by currentRecordID
         * @param state
         * @returns
         */
        getCurrentRecord: (state) => {
            return {
                foundset: state.allRecords.filter((record) => record.id === state.currentRecordID)[0],
            };
        },
        getAccountJobs: (state) => {
            return {
                foundset: state.allRecords.filter((record) => record.idAccount === storAccount().currentRecordID),
            };
        },
    },
});
