/* eslint-disable */
import { defineStore } from "pinia";
export const useMainStore = defineStore("storeMain", {
    state: () => {
        return {
            //generic
            storeWorking: true,
            mobileMenuOpen: false, // Initial state of the mobile menu
        };
    },
    actions: {
        /** Initalise */
        init() {
            console.log("storeBranch being initalised");
        },
        toggleMobileMenu() {
            this.mobileMenuOpen = !this.mobileMenuOpen;
        },
    },
    getters: {},
});
