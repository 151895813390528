/* eslint-disable */
import { defineStore } from "pinia";
import { db } from "@/js/firebase";
import { collection, onSnapshot, doc, deleteDoc, updateDoc, addDoc, query, } from "firebase/firestore";
let collectionRef;
let collectionQuery;
import { useAccountStore as storAccount } from "@/stores/storeAccount";
export const usePetStore = defineStore("storePet", {
    /** state
     * @description state is the current situation and is common accross the applicaiton
     */
    state: () => {
        const aRecords = []; //assigns the type before its added to the return
        return {
            //generic
            storeWorking: true,
            recordLoaded: false,
            //store specific
            currentRecordID: "",
            currentRecord: "",
            allRecords: aRecords, //all records returned when initalised
        };
    },
    /** actions
     * @description functions that are called to perform changes to the store elements.
     */
    actions: {
        /** Initalise */
        init() {
            collectionRef = collection(db, "pets");
            collectionQuery = query(collectionRef);
            this.readRecords();
        },
        /** Create Records
         * @description creates the record adding it to the selected collection
         */
        async createRecord(input) {
            const docRef = await addDoc(collectionRef, {
                id_account: input.id_account,
                name: input.name,
                typ: input.type,
                breed: input.breed,
                vet: input.vet,
                DOB: input.DOB,
            });
            console.log("Document written with ID: ", docRef.id);
        },
        /** Read Records
         * @description get the record list available to the user dependant on their permissions
         * onSnapshot monitors additions and updates the model
         */
        async readRecords() {
            this.recordLoaded = false;
            onSnapshot(collectionQuery, (querySnapshot) => {
                const aRecords = [];
                querySnapshot.forEach((doc) => {
                    const record = {
                        id: doc.id,
                        id_account: doc.data().id_account,
                        name: doc.data().name,
                        type: doc.data().type,
                        breed: doc.data().breed,
                        vet: doc.data().vet,
                        DOB: doc.data().DOB,
                    };
                    aRecords.push(record);
                });
                this.allRecords = aRecords; //add result to state
                this.recordLoaded = true;
            }),
                (error) => {
                    console.log("error.message: ", error.message);
                };
        },
        /** Update Record
         * @description Update the contents of the record with the data provided from the form as input parameter
         * @type {firstname:string, lastname:string} input - this is a test
         */
        async updateRecord(idToDelete, input) {
            await updateDoc(doc(collectionRef, idToDelete), {
                description: input,
            });
        },
        /** Delete Account
         * @description Note this wont delete the acocunt it will archive it and hide it from general use.  People associated with the account will also be blocked from access.
         */
        async deleteRecord(idToDelete) {
            //TODO add alert challenge when on a desktop (see Ionic for multi button alert)
            await deleteDoc(doc(collectionRef, idToDelete));
        },
    },
    /** getters
     * @description call getters from the vue to extract data from the store - this can be filtered with queries
     */
    getters: {
        getAllRecords: (state) => {
            return {
                foundset: state.allRecords, //state.accounts.filter(account => account === state.accounts)[0]
            };
        },
        /** Returns the current account for this user
         * @param state
         * @returns
         */
        getCurrentRecord: (state) => {
            return {
                foundset: state.allRecords.filter((record) => record.id === state.currentRecordID)[0],
            };
        },
        getAccountPets: (state) => {
            return {
                foundset: state.allRecords.filter((record) => record.id_account === storAccount().currentRecordID),
            };
        },
        getAccountPets2: (state) => {
            return {
                foundset: state.allRecords.filter((record) => record.account.id === storAccount().currentRecordID),
            };
        },
    },
});
